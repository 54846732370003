<template>
  <div class="steps-block">
    <div
      v-for="(step, index) in steps"
      :key="index"
      :class="['step-block', { 'active-step': step.step === currentStep }]"
    >
      <span class="step-title" :class="{ 'full-title': step.step === currentStep }">
        {{ step.title }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepsBlockComponent',
  props: {
    steps: Array,
    currentStep: [Number, String],
  }
};
</script>

<style scoped lang="scss">
.steps-block {
  display: flex;
  align-items: center;
  width: 90%;
  height: 40px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  overflow: hidden;

  .step-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 100%;
    border-radius: 16px;
    padding: 10px;
    font-size: 14px;
  }

  .step-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
  }

  .full-title {
    overflow: visible;
    white-space: normal;
    text-overflow: clip;
    max-width: none;
  }

  .active-step {
    background-color: #007DFB;
    color: #fff;
    z-index: 1;
  }
}
</style>
