export const htmlWithStyles = (missionName, rewardName, rewardValue, imgSrc) => `
<style>
    .mission-block {
        width: 200px;
        height: 80px;
        border: 1px solid #406A8C;
        display: flex;
        align-items: center;
        padding: 10px;
        background-color: #DCE6EF;
        border-radius: 10px;
        position: relative;
        overflow: visible;
        z-index: 1;
        user-select: none;
    }

    .image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 51px;
        border-radius: 5px;
        border: 1px solid #406A8C;
        overflow: hidden;
        flex-shrink: 0;
        user-select: none;
    }

    .image-wrapper img {
        height: 100%;
        object-fit: cover;
        max-width: 100%;
        border-radius: 5px;
        user-select: none;
    }

    .mission-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin-left: 10px;
        flex-grow: 1;
        user-select: none;
    }

    .mission-name {
        font-size: 16px;
        color: #141E28;
        text-transform: capitalize;
        user-select: none;
    }

    .reward-data {
        font-size: 14px;
        color: #304F69;
        user-select: none;
    }

    .mission-block > button {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #BB1196;
        color: #fff;
        border-radius: 5px;
        border: none;
        z-index: 10;
        user-select: none;
    }

    .connection-options {
        position: absolute;
        top: 50%;
        right: -55%;
        transform: translateY(-50%);
        display: none;
        z-index: 999;
        user-select: none;
    }

    .connection-options div {
        cursor: pointer;
        padding: 10px 20px;
        border-right: 10px;
        margin-top: 10px;
        background-color: #DCE6EF;
        border-radius: 10px;
        user-select: none;
    }
</style>
<div class="mission-block">
  <div class="image-wrapper">
    <img src="${imgSrc}" alt=""/>
  </div>
  <div class="mission-info">
    <span class="mission-name">${missionName}</span>
    <span class="reward-data">
        <span>${rewardName}</span>
        <span>${rewardValue}</span>
      </span>
  </div>
  <button class="connection-options-button">+</button>
  <div class="connection-options">
    <div class="option-must">Must</div>
    <div class="option-should">Should</div>
    <div class="option-mustNot">Mustn't</div>
  </div>
</div>
`;
