<template>
  <div class="missions-block">
    <div class="missions-block_header">
      <div class="missions-block_header_title">
        {{ 'Missions' }}
      </div>
      <div></div>
    </div>
    <table v-if="missionsList" class="styled-table">
      <thead>
      <tr>
        <th>ID</th>
        <th>Created</th>
        <th>Tags</th>
        <th>Name</th>
        <th>Scheduling</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="mission in missionsList" :key="mission.id">
        <td :title="mission.id" class="ellipsis">
          <a @click="selectAchievement(mission)" class="link">{{ mission.id }}</a>
        </td>
        <td v-html="formatDate(mission.created)" class="ellipsis"></td>
        <td class="ellipsis">{{ mission.tags.join(', ') }}</td>
        <td :title="mission.name" class="ellipsis">{{ mission.name }}</td>
        <td v-html="formatScheduling(mission.scheduling)" class="ellipsis"></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ListOfCloningMissions',
  props: {
    missionsList: Array,
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapActions('achievements', ['handleGetAchievementToClone']),
    selectAchievement(mission) {
      this.handleGetAchievementToClone({id: mission.id})
        .then(async data => {
          this.$emit('setCloneData', data[0]);
          this.$emit('closeModal')
        });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString();
      const formattedTime = date.toLocaleTimeString();
      return `${formattedDate}<br />${formattedTime}`;
    },
    formatScheduling(scheduling) {
      if (!scheduling) return '';
      const { startDate, endDate } = scheduling;
      const formattedStartDate = new Date(startDate).toLocaleDateString();
      const formattedEndDate = new Date(endDate).toLocaleDateString();
      return `${formattedStartDate}<br />${formattedEndDate}`;
    },
  }
};
</script>

<style scoped lang="scss">

.missions-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 90px;
  right: 0;
  z-index: 1000;
  background-color: #f1f2f4;
  width: 50%;
  height: 80%;
  border-radius: 20px 0 0 20px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2),
  10px 10px 20px rgba(0, 0, 0, 0.2),
  15px 15px 30px rgba(0, 0, 0, 0);

  &_header {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    &_title {
      color: rgba(0, 0, 0, 0.6);
      font-size: 22px;
      font-weight: 400;
      line-height: 33px;
      text-align: center;

    }
  }

  .styled-table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
  }

  .styled-table th {
    background-color: #f1f2f4;
    padding: 10px;
    text-align: left;
  }

  .styled-table td {
    padding: 10px;
    text-align: left;
  }

  .ellipsis {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .styled-table th:first-child,
  .styled-table td:first-child {
    border-top-left-radius: 10px;
  }

  .styled-table th:last-child,
  .styled-table td:last-child {
    border-top-right-radius: 10px;
  }

  .link {
    color: #007bff;
    cursor: pointer;
    text-decoration: none;
  }

  .link:hover {
    text-decoration: underline;
  }
}




</style>
